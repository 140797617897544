@import '../../../../styles/variables';

.title-component {
  align-items: center;

  h2 {
    margin-bottom: 0;
    text-align: center;
  }

  .button-container {
    text-align: right;

    button {
      background-color: $blue;
      color: white;
      padding: 10px;

      @media (max-width: $max-mobile-width) {
        padding: 5px;
      }
    }
  }
}
