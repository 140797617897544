@import '../../../../styles/variables';

.general-stats-component {
  border-radius: 3px;
  border: solid 1px $blue;
  padding: 10px;
  background-color: $lightblue;
  display: flex;

  @media (max-width: $max-mobile-width) {
    flex-direction: column;

    .processes-workers {
      border-right: none !important;
      border-bottom: solid 1px $blue;
      width: 100% !important;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .stats {
      flex-direction: column;
      width: 100% !important;

      div {
        margin-bottom: 10px;
      }
    }
  }

  h3 {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }

  .processes-workers {
    border-right: solid 1px $blue;
    width: 25%;
  }

  .stats {
    width: 75%;
  }

  .processed {
    color: $blue;
  }

  .failed {
    color: $red;
  }

  .scheduled {
    color: $orange;
  }
}
