.processes-component {
  .p-datatable .p-datatable-thead, .p-datatable .p-datatable-tbody {
    > tr > th:nth-child(2), > tr > th:nth-child(3), > tr > th:nth-child(4),
    > tr > td:nth-child(2), > tr > td:nth-child(3), > tr > td:nth-child(4) {
      text-align: right;
    }

    > tr > th:nth-child(1) {
      width: 35%;
    }

    > tr > th:nth-child(2) {
      width: 30%;
    }

    > tr > th:nth-child(3) {
      width: 20%;
    }

    > tr > th:nth-child(4) {
      width: 15%;
    }
  }
}
