@import '../../../../styles/variables';

.header-component {
  background-color: white;
  height: 60px;
  margin: 0;
  align-items: center;

  h2 {
    margin-bottom: 0;
    text-align: center;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;

    button {
      border: solid 1px $blue;
      color: $blue;
      background-color: white;
      height: 40px;
    }
  }

  img {
    max-height: 40px;
    max-width: 100%;
    width: initial;
  }
}
