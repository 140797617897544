@import '~primereact/resources/themes/nova-light/theme.css';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';

// Breakpoint
@import '~breakpoint-sass/stylesheets/breakpoint';

//
// Common files dependencies
//
// Functions
@import '~integration-common-files/src/scss/00-core/functions';

// Theme
@import '~integration-common-files/src/scss/00-core/themes/selectra';

// Variables
@import '~integration-common-files/src/scss/00-core/variables';

// Reboot
@import '~integration-common-files/src/scss/00-core/reboot';

// Grid
@import '~integration-common-files/src/scss/00-core/grid';

// Atoms
@import '~integration-common-files/src/scss/01-atoms/typography/headings';
@import '~integration-common-files/src/scss/01-atoms/typography/link';
@import '~integration-common-files/src/scss/01-atoms/typography/paragraph';
@import '~integration-common-files/src/scss/01-atoms/btn';
@import '~integration-common-files/src/scss/01-atoms/icon';

@import 'variables';

.hidden {
  display: none;
}

@media (max-width: $max-mobile-width) {
  .hidden-mobile {
    display: none;
  }
}

.error {
  color: $red;
}

.no-padding {
  padding: 0 !important;
}

.p-datatable .p-datatable-thead > tr > th {
  overflow: auto;
}
