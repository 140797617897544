@import '../../styles/variables';

.spinner-container {
  width: 100%;
  position: fixed;
  min-height: 100vh;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 13;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 30%;
    max-height: 95%;
  }

  @media (max-width: $max-mobile-width) {
    > img {
      max-width: 90%;
    }
  }
}
