@import '../../styles/variables';

.dashboard-component {
  min-height: 100vh;
  background-color: lightgray;
  padding-bottom: 20px;

  .container {
    background-color: white;
    margin-top: 20px;
    padding-bottom: 20px;

    > .row {
      > div {
        margin-top: 20px;
      }
    }

    .tabs {
      display: inline-flex;
      overflow-x: auto;
      padding: 0;
      margin: 0 !important;
      background-color: lightgray;

      @media (max-width: $max-mobile-width) {
        border-top: solid 1px $blue;
        border-bottom: solid 1px $blue;
        background-color: white;
      }

      button {
        border-radius: 0;
      }

      .selected {
        background-color: $blue;
        color: white;
      }

      .not-selected {
        color: $blue;
        background-color: white;
      }
    }
  }

  .p-datatable {
    .p-datatable-thead > tr > th {
      text-align: left;
      color: $blue !important;
      background-color: $lightblue !important;
      border: none;
    }

    .p-sortable-column-icon {
      color: $blue !important;
    }

    .p-datatable-tbody {
      > tr > td {
        border: none;
        border-bottom: solid 1px lightgray;
        background-color: white;
        white-space: nowrap;
        overflow: auto;
      }
    }

    .p-paginator {
      text-align: right;
      background-color: white;
      border: none;

      button:hover {
        background-color: white !important;
      }

      .p-paginator-first, .p-paginator-last, .p-paginator-pages {
        display: none;
      }

      .p-paginator-prev, .p-paginator-next {
        color: $blue;
      }
    }
  }

  .paginator-info {
    bottom: 4px;
    right: 80px;
    position: absolute;
  }
}
