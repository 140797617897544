@import '../../styles/variables';

.login-component {
  background: url('/back.svg');
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10%;

  .selectra-logo {
    width: 25%;
  }

  .google-login {
    background-color: white;
    padding: 20px;
    margin-top: 20px;
    width: 30%;
    text-align: center;

    button {
      color: #888;
      background-color: white;
      border: solid 1px #888;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;

      img {
        width: 1.25rem;
        height: auto;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: $max-mobile-width) {
    background-position: top;

    .selectra-logo {
      position: fixed;
      top: 15px;
      right: 15px;
      width: 40%;
    }

    .google-login {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
}
